.navbar{
  height: $navbar-brand-height;
  min-height: $navbar-brand-height;
  padding: 0;

  @include media-breakpoint-down(md){
    min-height: none;
    height: auto;
    background: $header;
    text-align: center;
  }

  .nav-item{
    padding:0 $grid-gutter-width/4;
  }

  a.nav-link{
    position:relative;
    font-weight: normal;
    color: $secondary;
    @include transition(all 250ms);

    &:after{
      content:'';
      position:absolute;
      height:2px;
      background-color: $secondary;
      bottom: 0;
      left: 0;
      width: 0;
      @include transition(width 250ms, background-color 250ms 250ms);
    }

    &:hover, &:focus{
      &:after{
        width: 100%;
        background-color: $primary;
      }
    }

    &.active{
      color: $primary;
    }
  }
}
