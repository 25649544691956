.loader{
  @include transition(all 500ms);
  //overflow: hidden;
  //max-height: 0px !important;
  -webkit-transform: translateY(50px);
  -moz-transform: translateY(50px);
  -ms-transform: translateY(50px);
  -o-transform: translateY(50px);
  transform: translateY(50px);
  opacity: 0;

  &.loaded{
    //max-height: 100% !important;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

  .square, .half, .third, .quarter{
    position:relative;
    .inner{
      position:absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      img{
        width:100%;
        height: 100%;
      }
    }
  }

  .square{
    padding-top: 100%;
  }

  .half{
    padding-top: 50%;
  }

  .third{
    padding-top: 33.33%;
  }

  .quarter{
    padding-top: 25%;
  }

  .border-2{
    border-width: 2px;
  }

  .border-3{
    border-width: 3px;
  }

  .flip{
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .shape{
    svg{
      .neg{
        display:none;
        a:hover &, a:focus &{
          display:block;
        }
      }

      .pos{
        display:block;
        a:hover &, a:focus &{
          display:none;
        }
      }
    }
  }

  .bg-overlay{
    top:0;
    left: 0;
    opacity: 0.35;
    a:hover &, a:focus &{
      opacity: 0;
    }


  }

.image-gallery{
  .bg-overlay{
    opacity: 0;

  }

  a:hover, a:focus{
    .bg-overlay {
      opacity: 0.5;
    }
  }

}

  @each $color, $value in $theme-colors{
    .svg-#{$color}{
      &, .svg{
        fill: $value !important;
      }
    }

    .hv-svg#{$color}{
      a:hover &, a:focus &{
        &, .svg {
          fill: $value !important;
        }
      }

    }

    .hv-text-#{$color}{
      a:hover &, a:focus &{
        color: $value !important;
      }
    }

    .hv-bg-#{$color}{
      a:hover &, a:focus &{
        background-color: $value !important;
      }
    }
  }

.font-size-base{
  font-size: $font-size-base;
  line-height: $line-height-base;
}

.font-size-lg{
  font-size: $font-size-lg;
  line-height: $line-height-base;
}

.pargraph--body{
  a{
    text-decoration: underline;
  }
}

