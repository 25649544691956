.navbar-brand{
  left:0;
  top:0;
  padding: 0 !important;
  padding: 0 !important;
  height: $navbar-brand-height;
  display: block;

  img{
    display:block;
    position: relative;
    max-height: $navbar-brand-height;
    width:auto;
    @include transition(all 250ms);
    @include media-breakpoint-down(md){
      height:100%;
    }
  }

  .front &{
      height: $navbar-brand-height * 2;
      img{
        position:relative;
        max-height:$navbar-brand-height * 2;
        width:auto !important;
      }
      @include media-breakpoint-up(lg){
        height: $navbar-brand-height;
        img {
          position: absolute;
        }
      }
    }

}

header.header{
  position: relative;
  &:before{
    content: '';
    width:100%;
    height: 100%;
    left:0;
    top: 0;
    position: absolute;
    background: $header;
    z-index:0;
    @include transition(all 250ms);
  }

  .navbar-toggler{
    height:$navbar-brand-height;
    padding: 0;
    margin:0;
  }

  &.front{
    &.with-image:before {
      height: $navbar-brand-height * 2;
    }
  }
}