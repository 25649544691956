$primary:#ab895b;
$secondary:#3c3c3b;
$tertiary:#eee;
$dark: $secondary;
$header: $primary;

.navbar .nav-link{
  &.active {
    color: #fff !important;
  }

  &:hover, &:focus{
    &:after{
      background: $secondary !important;
    }
  }
}

.intro{
  &.text-muted{
    color: darken(#fff, 25%) !important;
  }

}