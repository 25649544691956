body, html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*
.square img {
  width:calc(100% - 4px) !important;
  height:calc(100% - 4px) !important;
  margin:2px !important;
}
*/
.square .shape {

  /*padding:2px !important;*/
  /*border:2px solid #ab895b !important;*/

  position:relative;
  &:before {
    position:absolute;left:0;right:0;top:0;bottom:0;content:"";
    border:1px solid #ab895b;
  }
}
.square .inner:first-child {
  background:#ab895b !important;
}
