@import url('https://fonts.googleapis.com/css?family=Roboto');

$font-family-sans-serif: 'Roboto';
$font-family-base: $font-family-sans-serif;
$navbar-brand-height: 6rem;
$border-radius: 0;
$light: #eee;
$medium: #ccc;

$theme-colors: (
  'primary' : $primary,
  'secondary' : $secondary,
  'tertiary' : $tertiary,
  'sales' : $tertiary,
  'header' : $header,
  'medium' : $medium
);

$h1-font-size: 2.3rem;
$h2-font-size: 1.8rem;

$headings-font-weight: 400;
$headings-line-height: 1;